
import Vue from 'vue';

import SemKeywordsStandalone from '@/components/Products/SemKeywordsStandalone.vue';
import { mapActions } from 'vuex';
import { ProductServiceContract } from '../../injectables';
import { Services } from '../../injectables/tokens';
import { ProductConfigType } from '../../app/graphql/_types';
import { GetProductConfigByTypeQuery } from '../../injectables/services/product/graphql/queries/get-product-config-by-type.generated';
import AppBar from '@/entities/app/base-app-bar.vue';

export default Vue.extend({
  name: 'KeywordPlanner',

  useInjectable: [Services.Product],

  components: { SemKeywordsStandalone, AppBar },

  data: (): {
    productConfig: null | GetProductConfigByTypeQuery['getMediaplannerProductConfigByType'];
  } => ({
    productConfig: null,
  }),

  methods: {
    ...mapActions('product', ['clearKeywords']),
    clearKeywordResults(): void {
      this.clearKeywords();
    },
    async getProductConfig(agencyId: string) {
      const { isErr, unwrap } = await (this[Services.Product] as ProductServiceContract).getProductConfigByType(
        ProductConfigType.GoogleSearch,
        agencyId,
      );

      if (isErr()) {
        return null;
      }

      return unwrap();
    },
  },

  async mounted() {
    const agencyId = this.$store.state.agency.id;

    this.productConfig = await this.getProductConfig(agencyId);
  },
});
