
import Vue from 'vue';
import CsvUploadDialog from '@/components/CsvUploadDialog.vue';
import { KeywordSummary, SemKeyword } from '@/shared/types';
import { FileServiceContract } from '@/injectables';
import { Services } from '@/injectables/tokens';
import KeywordBody from '@/components/keywords/KeywordBody.vue';
import KeywordHeader from './keywords/KeywordHeader.vue';
import { debounce } from 'lodash';
import { KeywordsServiceContract } from '../injectables';

export default Vue.extend({
  name: 'ProductSemKeyword',

  useInjectable: [Services.Keywords, Services.File, Services.Env],

  components: { CsvUploadDialog, KeywordBody, KeywordHeader },

  props: {
    product: {
      type: Object,
    },
    geoSelection: {
      type: Object,
    },
    budget: {
      type: Number,
      default: 1000,
    },
    clientUrl: {
      type: String,
      default: '',
    },
    dates: {
      type: Array,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    budget: debounce(function (val) {
      if (this.generatedKeywords.length) {
        this.$store.dispatch('product/getKeywordsSummary', { keywords: this.generatedKeywords, budget: val });
      }
    }, 500),
  },

  data: (): {
    keywordIdeas: string[];
    customKeywords: string[];
    newKeywordIdea: string;
    newCustomKeyword: string;
    openFileUpload: boolean;
    publicPath: string;
  } => ({
    newCustomKeyword: '',
    newKeywordIdea: '',
    keywordIdeas: [],
    customKeywords: [],
    openFileUpload: false,
    publicPath: process.env.BASE_URL,
  }),

  computed: {
    uploadHelperText(): string {
      return `CSV file smaller than 2MB. Max ${this.entryLimit} keywords allowed`;
    },
    entryLimit(): number {
      return !this.generatedKeywords?.length
        ? (this.keywordsService as KeywordsServiceContract).ideasLimit
        : (this.keywordsService as KeywordsServiceContract).keywordEntryLimit;
    },
    generatedKeywords(): SemKeyword[] {
      return this.$store.state.product.keywords;
    },
    keywordsSummary(): KeywordSummary {
      return this.$store.state.product?.keywordsSummary || null;
    },
    loadingKeywords(): boolean {
      return (
        this.$store.state['product'].loadingCustomKeywords ||
        this.$store.state['product'].loadingKeywordsSummary ||
        this.$store.state['product'].loadingKeywords
      );
    },
  },

  methods: {
    updateNewKeywordIdea(value: string): void {
      this.newKeywordIdea = value;
    },
    updateNewCustomKeyword(value: string): void {
      this.newCustomKeyword = value;
    },
    clearKeywords(): void {
      this.keywordIdeas = [];
      this.$emit('clear-results');
    },
    async downloadKeywords(): Promise<void> {
      const fileService: FileServiceContract = this.fileService;

      const [startDate, endDate] = this.dates;

      this.$store.dispatch('showSnackbar', {
        content: 'Generating Excel file, please wait for download to start...',
        color: 'success',
        timeout: -1,
        withLoader: true,
      });

      const trackDownloadProgress = () => {
        this.$store.dispatch('hideSnackbar');
      };

      const { isErr, unwrapErr } = await fileService.downloadKeywordPlannerXlsx(
        {
          name: 'kp_results',
          budget: this.budget,
          keywordStatistics: this.generatedKeywords,
          geoSelections: this.geoSelection,
          startDate,
          endDate,
          ...(this.clientUrl && { url: this.clientUrl }),
        },
        trackDownloadProgress,
      );

      if (isErr()) {
        this.$log('error', 'ProductSemKeywords/export error', unwrapErr());

        this.$store.dispatch('showSnackbar', {
          content: 'error generating Excel file, please try again',
          color: 'error',
        });
      }
    },
    generateKeywords(): void {
      this.saveKeywordIdea();

      const payload = {
        keywords: this.keywordIdeas,
        geoSelection: this.geoSelection || {},
        budget: this.budget,
        ...(this.clientUrl && { clientUrl: this.clientUrl }),
        ...(this.product?.id && { productConfigId: this.product?.id }),
      };

      // eslint-disable-next-line no-console
      this.$store.dispatch('product/getSemKeywords', payload).catch(err => console.error(err));
    },
    fetchCustomKeywords(): void {
      this.saveCustomKeyword();
      if (!this.customKeywords?.length) {
        return;
      }
      const payload = { keywords: this.customKeywords, geoSelection: this.geoSelection, budget: this.budget };
      this.$store
        .dispatch('product/getCustomKeywords', payload)
        .then(keywords => {
          if (keywords?.length) {
            this.customKeywords = [];
          }
        })
        // eslint-disable-next-line no-console
        .catch(err => console.error(err));
    },
    toggleSelectedKeyword(keyword: string): void {
      const customKeywordIndex = this.customKeywords.findIndex(keywordObj => keywordObj.keyword === keyword);
      if (customKeywordIndex !== -1) {
        this.customKeywords[customKeywordIndex].isSelected = !this.customKeywords[customKeywordIndex].isSelected;
        return;
      }
      this.$store
        .dispatch('product/toggleSelectedKeyword', { keyword, budget: this.budget })
        // eslint-disable-next-line no-console
        .catch(err => console.error(err));
    },
    removeCustomKeyword(keyword: string): void {
      const keywordIndex = this.customKeywords.indexOf(keyword);
      if (keywordIndex !== -1) {
        this.customKeywords.splice(keywordIndex, 1);
      }
    },
    removeIdea(idea: string): void {
      const ideaIndex = this.keywordIdeas.indexOf(idea);
      if (ideaIndex !== -1) {
        this.keywordIdeas.splice(ideaIndex, 1);
      }
    },
    saveCustomKeyword(): void {
      const trimmed = this.newCustomKeyword.trim();
      if (
        trimmed.length &&
        !this.customKeywords.includes(trimmed) &&
        !this.generatedKeywords.some(kw => kw.keyword === trimmed)
      ) {
        this.customKeywords.push(trimmed);
        this.newCustomKeyword = '';
      }
    },
    saveKeywordIdea(): void {
      const trimmed = this.newKeywordIdea.trim();
      if (trimmed.length && !this.keywordIdeas.includes(trimmed)) {
        this.keywordIdeas.push(trimmed);
        this.newKeywordIdea = '';
      }
    },
    applyUploadedFile(keywords: Array<string> = []): void {
      if (!keywords.length) return;
      let hasDuplicates = false;
      let hasMaxKeywords = false;

      if (this.generatedKeywords.length) {
        keywords.forEach(i => {
          if (this.customKeywords.length >= this.entryLimit) {
            hasMaxKeywords = true;
          } else if (this.customKeywords.indexOf(i) === -1) {
            this.customKeywords.push(i);
          } else {
            hasDuplicates = true;
          }
        });
      } else {
        keywords.forEach(i => {
          if (this.keywordIdeas.length >= this.entryLimit) {
            hasMaxKeywords = true;
          } else if (this.keywordIdeas.indexOf(i) === -1) {
            this.keywordIdeas.push(i);
          } else {
            hasDuplicates = true;
          }
        });
      }

      if (hasDuplicates || hasMaxKeywords) {
        const snackbarMessage = hasMaxKeywords
          ? `Only ${this.entryLimit} entries permitted at a time, some have been removed`
          : 'Some keywords already added';
        this.$store.dispatch('showSnackbar', { content: snackbarMessage, color: 'warning' });
      }
    },
  },
});
