
import Vue from 'vue';

import { Services, Models } from '@/injectables/tokens';
import { ValidationServiceContract, GeoModelContract } from '@/injectables';

import DateRangePickerWithQuickPeriods from '@/components/Proposal/DatePickerWithQuickPeriods.vue';
import EditableGeo from '@/widgets/instant-io/ui/geoselections-card/editable-card.vue';
import ProductSemKeyword from '@/components/ProductSemKeyword.vue';
import { mapActions } from 'vuex';
import { UnsafeAny } from '@/shared/legacy/classes';

const geoArrayNameMap = {
  DMA: 'dmaList',
  COUNTY: 'countyList',
  STATE: 'stateList',
  CITY: 'cityList',
  ZIP: 'zipList',
};

export default Vue.extend({
  name: 'SemKeywordsStandalone',

  useInjectable: [Services.Validation, Models.Geo],

  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    ProductSemKeyword,
    EditableGeo,
    DateRangePickerWithQuickPeriods,
  },

  data: (): {
    currentGeoType: string;
    dates: string[];
    budget: number;
    clientUrl: string;
    maxGeos: number;
    mapData: {

      stateList: string[];
      dmaList: string[];
      cityList: string[];
      countyList: string[];
    };
    geoSelections: string[];
    recommendedBudgetList: number[];
    isBudgetValidationError: boolean;
  } => ({
    maxGeos: 100,
    currentGeoType: 'DMA',
    dates: [],
    budget: 1000,
    clientUrl: '',
    recommendedBudgetList: [2500, 5000, 10000],
    isBudgetValidationError: false,
    geoSelections: [],
    mapData: {
      stateList: [],
      dmaList: [],
      cityList: [],
      countyList: [],
    },
  }),

  computed: {
    budgetMinimum(): number {
      return 1000;
    },
    budgetMaximum(): number {
      return this.$store.getters['newProposal/proposalBudgetMax'];
    },
    rules() {
      const validationService: ValidationServiceContract = this.validationService;

      return {
        maxLength: validationService.maxLengthValidatorFactory(),
        domain: validationService.isDomainValidatorFactory(),
      };
    },
  },

  methods: {
    ...mapActions('product', { clearKeywords: 'clearKeywords' }),
    cleanGeo(geoName): string {
      return (this[Models.Geo] as GeoModelContract).cleanGeoString(geoName);
    },
    removeGeo(item): void {
      const updatedMapData = { ...this.mapData };

      const itemName = (this[Models.Geo] as GeoModelContract).cleanGeoString(item);

      Object.keys(updatedMapData).forEach((geoType: string) => {
        updatedMapData[geoType] = updatedMapData[geoType].filter(
          geo => (this[Models.Geo] as GeoModelContract).cleanGeoString(geo) !== itemName,
        );
      });

      this.mapData = updatedMapData;
      this.geoSelections = this.geoSelections.filter(
        (geo: UnsafeAny) => (this[Models.Geo] as GeoModelContract).cleanGeoString(geo) !== itemName,
      );
    },
    addGeo(geoObj: UnsafeAny): void {
      this.geoSelections.push(geoObj);
      this.mapData[geoArrayNameMap[this.currentGeoType]].push(geoObj);
    },
    changeGeoType(geoType: string): void {
      this.currentGeoType = geoType;
    },
    isBetweenBudget(from: number, to: number) {
      return (this.validationService as ValidationServiceContract).isBetweenValidatorFactory({
        from,
        to,
        messages: {
          min: 'Budget must be at least',
          max: 'Budget must not exceed',
        },
      });
    },
  },

  beforeDestroy() {
    this.clearKeywords();
  },
});
